import React, { useRef } from 'react';
import '../layout.css';
import { HemisphericLight } from '@babylonjs/core/Lights/hemisphericLight';
import {Color4, Vector3} from '@babylonjs/core/Maths/math';
import BabylonScene from 'babylonjs-hook';

import {
     CubeTexture,
    ExecuteCodeAction, FollowCamera, FreeCamera, GlowLayer, MeshBuilder,
    StandardMaterial,
    Texture}
    from "@babylonjs/core";

import {Color3} from "@babylonjs/core/Maths/math";
import {SceneLoader} from "@babylonjs/core";
import {ActionManager} from "@babylonjs/core/Actions/actionManager";

let box;
let babylonLink;
let city;


const onSceneReady = scene => {

    scene.clearColor = new Color4(0, 0.6666, 0.8941, 0.5);
    scene.clearColor = new Color3(0, 0.6666, 0.8941);
    const canvas = scene.getEngine().getRenderingCanvas();


    var environment =scene.createDefaultEnvironment({
        createSkybox: false,
        // skyboxSize: 1000,
        // skyboxColor:Color3.Magenta(),
        createGround: true,
        groundSize: 1000,
        groundColor: Color3.Gray(),
        // groundColor: Color3.Green(),
        groundYBias: 0
    });

    var skyMaterial = new StandardMaterial("skybox", scene);
    skyMaterial.backFaceCulling = false;
    skyMaterial.reflectionTexture = new CubeTexture("/textures/", scene);
    skyMaterial.reflectionTexture = new CubeTexture("/textures/skybox/", scene);
    skyMaterial.reflectionTexture.coordinatesMode = Texture.SKYBOX_MODE;
    skyMaterial.diffuseColor = new Color3(0, 0, 0);
    skyMaterial.specularColor = new Color3(0, 0, 0);

    var skybox = MeshBuilder.CreateBox("skyBox", {size:10000.0}, scene);
    skybox.material = skyMaterial;


    var camera = new FreeCamera("FreeCamera", new Vector3(0, 100, -1), scene);
    camera.attachControl(canvas, true);
    camera.ellipsoid = new Vector3(1, 1, 1);
    camera.speed = 0.85

    scene.gravity = new Vector3(0, -0.5, 0);



    scene.gravity = new Vector3(0, -0.005, 0);
    scene.collisionsEnabled = true;

    camera.checkCollisions = true;
    camera.applyGravity = true;


    // var skybox = MeshBuilder.CreateBox("skyBox", {size:1000.0}, scene);
    // var skyboxMaterial = new StandardMaterial("skyBox", scene);
    // skyboxMaterial.backFaceCulling = false;
    // skyboxMaterial.reflectionTexture = new CubeTexture("textures/skybox3", scene);
    // skyboxMaterial.reflectionTexture.coordinatesMode = Texture.SKYBOX_MODE;
    // skyboxMaterial.disableLighting = true;
    // skybox.material = skyboxMaterial;


    // This creates a light, aiming 0,1,0 - to the sky (non-mesh)
    var light = new HemisphericLight("light", new Vector3(0, 1, 0), scene);
    // Default intensity is 1. Let's dim the light a small amount
    light.intensity = 0.61;

    //Creates a new standard material and sets the diffuse color to blue.
    var newMaterial = new StandardMaterial;
    newMaterial.name = "newMaterial";
    newMaterial.diffuseColor = new Color3.Blue;

    var boxMaterial1 = new StandardMaterial("material1", scene);
    // boxMaterial1.emissiveColor = new Color3(0, 0.58, 0.86);
    boxMaterial1.emissiveColor = new Color3(0, 0, 0);

    var gl = new GlowLayer("glow", scene);
    gl.intensity = 0.77;



    box = MeshBuilder.CreateBox("box", {size: 50}, scene);
    box.position.x = 100;
    box.position.y = 200;
    box.position.z = 100;


    box.material = boxMaterial1;

    box.actionManager = new ActionManager(scene);
    box.actionManager.registerAction(
        new ExecuteCodeAction(
            ActionManager.OnPickTrigger,
            () => {
                window.open("../interactive", "_self");
            }
        )
    );



    city = SceneLoader.ImportMesh("",
        "https://raw.githubusercontent.com/FTi130/gatsby-site/master/src/components/assets/",
        "portaNuova.babylon",
        scene,
        function(newMeshes)
        {
            newMeshes[0].checkCollisions = true

            for(let i = 0; i < newMeshes.length; i++) {

                console.log(newMeshes[i]);
                newMeshes[i].checkCollisions = true;
            }

            var smth = newMeshes[0];
            console.log('HERE')
            console.log(smth);
            camera.target(smth);

        var sphere = newMeshes[0].getChildMeshes()[0];
        sphere.material = newMaterial;
        sphere.checkCollisions = true;
        //console.log(sphere)
            sphere.checkCollisions = true;
    });


}
/**
 * Will run on every frame render.  We are spinning the box on y-axis.
 */

const onRender = scene => {
    if (box !== undefined) {
        var deltaTimeInMillis = scene.getEngine().getDeltaTime();

        const rpm = 10;
        box.rotation.y += ((rpm / 80) * Math.PI * 2 * (deltaTimeInMillis / 1000));
        box.rotation.z += ((rpm / 100) * Math.PI * 2 * (deltaTimeInMillis / 1000));

    }

    // if (box !== undefined) {
    //     var deltaTimeInMillis = scene.getEngine().getDeltaTime();
    //
    //     const rpm = 10;
    //     box.rotation.y += ((rpm / 80) * Math.PI * 2 * (deltaTimeInMillis / 1000));
    //     box.rotation.z += ((rpm / 100) * Math.PI * 2 * (deltaTimeInMillis / 1000));
    //
    // }
    // if (me !== undefined) {
    //     var deltaTimeInMillis = scene.getEngine().getDeltaTime();
    //
    //         const rpm = 10;
    //         me.rotation.y += ((rpm / 80) * Math.PI * 2 * (deltaTimeInMillis / 1000));
    //
    // }

}

export default () => {
    babylonLink = useRef(null);

    return (
        <>
            <BabylonScene antialias onSceneReady={onSceneReady} onRender={onRender} id='render-canvas' />
            <a ref={babylonLink} target="_self" rel="noopener noreferrer" href="https://pavli.netlify.app/">
            </a>
        </>
    )
}
